<template>
  <v-container fluid>
    <v-layout align-center justify-center dark :color="manifest.theme_color">
      <v-flex xs12 sm10 md6>
        <v-card :loading="loading_status" class="mx-auto my-12" max-width="374">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img contain height="250" :src="weblogo"></v-img>

          <v-card-title>{{ this.company.name }}</v-card-title>
          <v-card-subtitle>{{ this.company.group_name }}</v-card-subtitle>
          <v-card-text> </v-card-text>
          <v-divider class="mx-4"></v-divider>

          <v-card-actions>
            <v-bottom-navigation
              grow
              v-if="!$vuetify.breakpoint.xsOnly"
              class="elevation-0"
            >
              <v-btn @click="$router.push({ name: '/', params: { store: true } })">
                <span>Catálogo</span>
                <v-icon x-large> mdi-home-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="this.company.mobile"
                target="_blank"
                :href="open_whatsapp(company.mobile)"
              >
                <span>Whatsapp</span>
                <v-icon x-large>mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn v-if="this.company.mobile" :href="'tel:' + company.mobile">
                <span>Llamar</span>
                <v-icon x-large>mdi-phone-outline</v-icon>
              </v-btn>
              <v-btn v-if="this.company.facebook">
                <span>Facebook</span>
                <v-icon x-large>mdi-facebook</v-icon>
              </v-btn>

              <v-btn
                v-if="this.company.instagram"
                @click="open_instagram(company.instagram)"
              >
                <span>Instagram</span>
                <v-icon x-large>mdi-instagram</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <v-snackbar
      v-model="snackToast.dialog"
      :timeout="snackToast.timeout_error"
      absolute
      bottom
      dark
      :color="snackToast.color"
      elevation="24"
    >
      {{ this.snackToast.text }}
    </v-snackbar> -->

    <v-bottom-sheet v-model="snackToast.dialog" :timeout="snackToast.timeout_error">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="green" dark v-bind="attrs" v-on="on"> Open Persistent </v-btn>
      </template> -->
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="snackToast.dialog = !snackToast.dialog"
        >
          close
        </v-btn>
        <div class="py-3">This is a bottom sheet using the persistent prop</div>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-navigation app grow v-if="$vuetify.breakpoint.xsOnly" class="mt-2">
      <v-btn @click="$router.push({ name: '/', params: { store: true } })">
        <v-icon large> mdi-home-circle-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="this.company.mobile"
        target="_blank"
        :href="open_whatsapp(company.mobile)"
      >
        <span>Whatsapp</span>
        <v-icon x-large>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn v-if="this.company.mobile" :href="'tel:' + company.mobile">
        <span>Llamar</span>
        <v-icon x-large>mdi-phone-outline</v-icon>
      </v-btn>
      <v-btn v-if="this.company.facebook">
        <span>Facebook</span>
        <v-icon x-large>mdi-facebook</v-icon>
      </v-btn>

      <v-btn v-if="this.company.instagram" @click="open_instagram(company.instagram)">
        <span>Instagram</span>
        <v-icon x-large>mdi-instagram</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
//import { webserver } from "../../services/webserver.js";
//import { Console } from "console";
//import createParty from "../../utils/createParty.js";
// import VueQr from "vue-qr";
export default {
  name: "Welcome",
  components: {},
  data() {
    return {
      weblogo: null,
      stores: [],
      manifest: {},
      logo: "",
      logo_lg: "",
      color: "teal darken-2",
      flat: null,
      isXs: false,
      open: [],
      mobile: null,
      f_required: [(v) => !!v || "Requerido"],
      act: true,
      act_code: "",
      valid: false,
      loading_status: false,
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 6 || "EL código debe tener 6 números].",
      ],
      countDown: 60,
      token: "",
      snackToast: {
        timeout_error: -1,
        dialog: false,
        text: "",
        color: "success",
      },
      profile_dialog: false,
      countries: [],
      state: [],
      city: [],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      token_dialog: false,
      company: this.$store.getters.company,
      profile: this.$store.getters.profile,
      items: [
        {
          id: 0,
          name: "Catálogo",
          icon: "mdi-storefront-outline",
          to: "/",
          params: { store: true },
          color: "#064c72",
        },
        {
          id: 2,
          name: "Mis Ordenes",
          icon: "mdi-file-document-multiple-outline",
          to: "MyOrders",
          params: null,
          color: "#064c72",
        },
        // {
        //   id: 3,
        //   name: "Mi Perfil",
        //   icon: "mdi-account-outline",
        //   to: "Profile",
        //   color: "#064c72",
        // },
      ],
      cart: 0,
    };
  },
  beforeMount() {
    this.company = this.$store.getters.company;
    this.profile = this.$store.getters.profile;
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = "../../" + root + "/logo.webp";
  },
  mounted() {
    this.$store.dispatch("setNavStatus", true);
    this.cartqty();
    //console.log("mounted", this.company, this.profile);
    //this.snackToast.dialog = true;
    //console.log("&&&&", this.$store.getters.getManifest);
  },
  watch: {
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.company = newVal;
      }
    },
    "$store.getters.getStores"(newVal) {
      if (newVal) {
        this.stores = newVal;
      }
    },
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.client = this.$store.getters.profile;
      }
    },
  },
  computed: {},
  methods: {
    getlogo(e) {
      const root = e.url.replaceAll(".", "");
      return "../../" + root + "/logo.webp";
    },
    goto(e) {
      //console.log("goto", e);
      this.$router.push({ name: e.to, params: e.params });
    },

    go2store(e) {
      window.open("https://" + e.url, "_blank");
    },
    cartqty() {
      //console.log("cartqty", localStorage.cart ? localStorage.cart : "no cart");
      if (localStorage.cart) {
        var itms = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        itms.forEach((itm) => {
          this.cart += parseFloat(itm.qty);
        });
      }
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    open_instagram(data) {
      window.open("https://www.instagram.com/" + data, "_blank");
    },
    open_whatsapp(data) {
      data = data.replace("-", "");
      data = data.replace("(", "");
      data = data.replace(")", "");
      data = data.replace(" ", "");
      var URL =
        "https://api.whatsapp.com/send?phone=+57" +
        data +
        "&text=" +
        encodeURI("Deseo más información ...");
      return URL;
    },

    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    gotoCart() {
      this.$router.push({ name: "Store", params: { Cart: true } });
    },
  },
};
</script>

<style></style>
